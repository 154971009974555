<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="px-1 py-50">
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-3">
                <h3 class="m-0">
                  {{ $t("Formularios") }}
                </h3>
              </div>
              <div>
                <b-link :to="{ name: 'createForm' }">
                  <b-button
                    variant="primary"
                    class="mr-1 text-nowrap"
                  >
                    {{ $t("Crear nuevo") }}
                  </b-button>
                </b-link>
                <span
                  class="ml-1 cursor-pointer"
                  @click="visibleFilter = !visibleFilter"
                >
                  <feather-icon
                    icon="FilterIcon"
                    size="20"
                  />
                </span>
              </div>
            </div>
            <div>
              <b-collapse
                v-model="visibleFilter"
                class="mt-2"
              >
                <b-row>
                  <b-col
                    sm="10"
                    md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="searchTerm"
                        type="text"
                        class="d-inline-block"
                        :placeholder="$t('Busqueda')"
                      />
                    </b-input-group>
                  </b-col>
                  <b-col
                    sm="2"
                    md="4"
                    style="padding-left: 0px"
                  >
                    <b-button
                      variant="primary"
                      @click="handleSearch"
                    >
                      {{ $t("Buscar") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
          </div>
          <b-table
            v-if="items"
            hover
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(active)="data">
              <b-badge
                v-if="data.item.active === 1"
                variant="success"
              >
                {{ $t("Activado") }}
              </b-badge>
              <b-badge
                v-else
                variant="danger"
              >
                {{ $t("Desactivado") }}
              </b-badge>
            </template>
            <template #cell(company)="data">
              <span v-if="data.item.company && data.item.company.name">{{
                data.item.company.name
              }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(client)="data">
              <span v-if="data.item.client && data.item.client.name">{{
                data.item.client.name
              }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(events)="data">
              <b-badge
                v-for="act in data.item.actions"
                :key="`${data.item.id}-${act.id}`"
                class="mr-1"
              >
                {{ act.name }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <span
                  class="mr-1"
                  @click="previewForm(data.item.id)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="16"
                  />
                </span>
                <b-link
                  v-if="canEdit(data.item)"
                  :to="{ name: 'editForm', params: { id: data.item.id } }"
                  class="mr-1"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                  />
                </b-link>
                <span
                    v-if="data.item && !Array.isArray(data.item.tag) && data.item.tag.length !== 0"
                  class="mr-1"
                  @click="seeTag(data.item.tag)"
                >
                  <img
                    src="/img/qr-code-solid.svg"
                    alt="QR Code Icon"
                  >
                </span>
                <span
                  v-if="canEdit(data.item)"
                  class="text-danger cursor-pointer"
                  @click="deleteForm(data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
                <b-link class="ml-1">
                  <feather-icon
                    icon="CopyIcon"
                    size="16"
                    @click="cloneForm(data.item)"
                  />
                </b-link>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="ml-50 mr-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{ $t("Total") }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modalPreviewForm"
      v-model="modalPreview"
      hide-footer
      centered
      size="lg"
      :title="form.name"
    >
      <Preview
        v-if="form && form.formElements"
        :form="form"
      />
    </b-modal>
    <b-modal
      id="modalSeeTag"
      v-model="modalSeeShow"
      hide-footer
      :hide-header="true"
      centered
      size="md"
    >
      <div v-if="imageCurrentTag">
        <img
          :src="imageCurrentTag"
          class="img-fluid w-100"
        >
        <div class="text-center my-2">
          <b-button
            variant="primary"
            @click.prevent="downloadItem(imageCurrentTag, 'tag-' + currentTagID)"
          >
            {{ $t("DESCARGAR") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <ConfirmCloneModal
      ref="confirmCloneModal"
      :form="formToClone"
      @cloned="successCloneForm"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { BButton, BModal } from 'bootstrap-vue'
import Preview from '@/views/forms/Preview'
import { config } from '@/shared/app.config'
import axios from 'axios'

export default {
  components: {
    BButton,
    BModal,
    Preview,
    ConfirmCloneModal: () => import('@/components/forms/modals/ConfirmCloneFormModal.vue'),
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      pages: ['10', '15', '25'],
      searchTerm: '',
      formToClone: null,
      visibleFilter: false,
      modalPreview: false,
      currentTagID: '',
      modalSeeShow: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'forms/getItemsEdit',
      totalItems: 'forms/getTotalItemsEdit',
      role: 'auth/getRole',
      currentClient: 'clients/getCurrentClient',
      form: 'forms/getForm',
    }),
    imageCurrentTag() {
      return (this.currentTagID) ? `${config.webApiBase}/getqr/${this.currentTagID}` : ''
    },
    columns() {
      const generalColumns = [
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: this.$t('Nombre'),
          key: 'name',
        },
        {
          label: this.$t('Estado'),
          key: 'active',
        },
      ]

      const superAdminColumns = [
        {
          label: this.$t('Compañía'),
          key: 'company',
        },
        {
          label: this.$t('Cliente'),
          key: 'client',
        },
      ]

      const actions = [
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ]

      return this.role === 'admin_cliente'
        ? [...generalColumns, ...actions]
        : [...generalColumns, ...superAdminColumns, ...actions]
    },
  },
  created() {
    this.chargeData()
  },
  methods: {
    ...mapActions({
      listEdit: 'forms/getListEdit',
      delete: 'forms/delete',
      getForm: 'forms/getForm',
    }),
    seeTag(tag) {
      this.currentTagID = tag.id
      this.modalSeeShow = true
    },
    downloadItem(url, label) {
      axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'image/png' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    chargeData() {
      this.listEdit({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteForm(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t('EliminarConfirm', { nombre: name }), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
    async previewForm(id) {
      await this.getForm(id)
      this.modalPreview = true
    },
    cloneForm(item) {
      this.formToClone = item
      this.$bvModal.show('confirmCloneModal')
    },
    successCloneForm() {
      this.formToClone = null
      this.$bvModal.hide('confirmCloneModal')
    },
    canEdit(item) {
      if (this.role === 'super_admin' || this.role === 'admin_empresa') {
        return true
      } if (this.role === 'admin_cliente' && this.currentClient && item.client && this.currentClient.id === item.client.id) {
        return true
      }
      return false
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
